<template>
  <section class="userLocation">
    <h2 class="userLocation__title">Empresa *</h2>
    <div class="userLocation__contentRow">
      <CustomCheckboxVue :id="company.id" :label="company.name" :isChecked="company.isSelected" v-for="company in companies" :key="company.id" @toggleSelection="setNewCompanies"/>
    </div>
    <h2 class="userLocation__title">Sede</h2>
    <div class="userLocation__contentInput">
      <label for="city">Ciudad *</label>
      <select
        class="userLocation__input"
        id="city"
        ref="citySelect"
        :disabled="isDisabledSelect"
        v-model="$store.state.user.dataUser.city"
        @change="changeValues(['office', 'room']), validateSelect('city', 'citySelect', 'la ciudad')"
      >
        <option value="">Seleccione una ciudad</option>
        <option v-for="city in cities" :value="city.value" :key="city.value">{{ city.text }}</option>
      </select>
    </div>
    <span ref="city" class="userLocation__error"></span>
    <div class="userLocation__contentInput" v-if="$store.state.user.dataUser.hasOwnProperty('office')">
      <label for="office">Oficina</label>
      <select
        class="userLocation__input"
        id="office"
        ref="officeSelect"
        :disabled="isDisabledSelect"
        v-model="$store.state.user.dataUser.office"
        @change="changeValues(['room']), validateSelect('office', 'officeSelect', 'la oficina')"
      >
        <option value="">Seleccione una oficina</option>
        <option v-for="office in offices" :value="office.value" :key="office.value">{{ office.text }}</option>
      </select>
    </div>
    <span ref="office" class="userLocation__error"></span>
    <div class="userLocation__containerCol">
      <div class="userLocation__contentInput" v-if="$store.state.user.dataUser.hasOwnProperty('workShiftName')">
        <label for="workShift">Turno *</label>
        <select
          class="userLocation__input"
          id="workShift"
          ref="workShiftSelect"
          :disabled="isDisabledSelect"
          v-model="$store.state.user.dataUser.workShiftName"
          @change="changeValues(['room']), validateSelect('room', 'workShiftSelect', 'el turno')"
        >
          <option value="">Seleccione</option>
          <option v-for="workShift in workShifts" :value="workShift.value" :key="workShift.value">{{ workShift.text }}</option>
        </select>
      </div>
      <div class="userLocation__contentInput" v-if="$store.state.user.dataUser.hasOwnProperty('room')">
        <label for="room">Habitación *</label>
        <select
          class="userLocation__input"
          id="room"
          ref="roomSelect"
          :disabled="isDisabledSelect"
          v-model="$store.state.user.dataUser.room"
          @change="validateSelect('room', 'roomSelect', 'la habitación')"
        >
          <option value="">Seleccione</option>
          <option v-for="room in getRoomOptions" :value="room.value" :key="room.value">{{ room.text }}</option>
        </select>
      </div>
    </div>
    <span ref="room" class="userLocation__error"></span>
    <h2 class="typeUser__title">Area *</h2>
    <select
      class="userLocation__input"
      ref="areaSelect"
      v-model="$store.state.user.dataUser.area"
      @change="validateSelect('area', 'areaSelect', 'el area')"
    >
      <option value="">Seleccione un area</option>
      <option v-for="area in optionsAreas" :value="area.value" :key="area.value">{{ area.name }}</option>
    </select>
    <span ref="area" class="userLocation__error"></span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatSelection } from "@/js/validation.js";
import CustomCheckboxVue from '@/components/input/CustomCheckbox.vue';
export default {
  components: {CustomCheckboxVue}
,  data() {
    return {
      workShifts: [
        { value: "morning", text: "Mañana" },
        { value: "late", text: "Tarde" },
        { value: "night", text: "Noche" },
      ],
    };
  },
  computed: {
    ...mapGetters("users", ["getModels"]),
    ...mapGetters("rooms", { getRooms: "getData" }),
    ...mapGetters(['listCompanies']),
    companies(){
      return this.listCompanies.map(({_id, name})=> ({id: _id, name, isSelected: this.user.companies.includes(_id)}))
    },
    user() {
      return this.$store.state.user.dataUser;
    },
    isEditUser() {
      return !!this.user?._id;
    },
    isDisabledSelect() {
      return this.user.role === "model" && !this.isEditUser;
    },
    cities() {
      const cities = this.$cities.map((city) => ({ value: city, text: city }));
      return cities;
    },
    offices() {
      const offices = this.$store.getters["offices/getData"]
        .filter(({ cityName }) => cityName === this.user.city)
        .map(({ officeName }) => ({ value: officeName, text: officeName }));
      return offices;
    },
    getRoomOptions() {
      if (this.user.role !== "model" || !this.user.workShiftName) {
        return [];
      }
      if (this.user.role === "model" && this.isSatelite) {
        return [{ value: "Satelite", text: "Satelite" }];
      }

      const models = this.getModels;
      const rooms = this.getRooms
        .filter((room) => {
          const isOfficeRoom = room.officeName === this.user.office;
          const verifyModels = !models.some((model) => {
            const isEqWorkShift = model.workShift.name === this.user.workShiftName;
            const isEditRoom = model.room === room.roomName && model._id !== this.user._id;

            return isEqWorkShift && isEditRoom;
          });

          return isOfficeRoom && verifyModels;
        })
        .map(({ roomName }) => ({ value: roomName, text: roomName }));

      return rooms;
    },
    optionsAreas() {
      const areas = this.$store.state.user.companyAreas;
      return areas.map(({ _id, name }) => ({ name, value: _id }));
    },
    isSatelite() {
      return this.$store.state.user.dataUser?.office === "Satelite";
    },
  },
  methods: {
    setNewCompanies(id){
      const companyIdx = this.$store.state.user.dataUser.companies.findIndex((company) => company === id);

      if (companyIdx !== -1) {
        this.$store.state.user.dataUser.companies.splice(companyIdx, 1);
      } else {
        this.$store.state.user.dataUser.companies.push(id);
      }
    },
    changeValues(valuesToChange) {
      valuesToChange.forEach((key) => this.$store.state.user.dataUser.hasOwnProperty(key) && (this.$store.state.user.dataUser[key] = ""));
    },
    validateSelect(ref, refLocal, text, data) {
      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];
      const dataToSet = data ?? event?.srcElement ?? { value: "" };

      const response = formatSelection(reference, text, dataToSet);

      this.updateStyles(reference, referenceLocal, response);
      return response;
    },
    updateStyles(reference, referenceLocal, response) {
      reference.style.display = response ? "none" : "block";
      referenceLocal.style["border-color"] = response ? "#d8d8d8" : "#bd0909";
    },
    verifyNext() {
      const { city, office, workShiftName, room, area } = this.user;
      const citySelect = this.validateSelect("city", "citySelect", "la ciudad", { value: city });
      const officeSelect = !this.user.hasOwnProperty("office") || this.validateSelect("office", "officeSelect", "la oficina", { value: office });
      const workShiftSelect =
        !this.user.hasOwnProperty("workShiftName") || this.validateSelect("room", "workShiftSelect", "el turno", { value: workShiftName });
      const roomSelect = !this.user.hasOwnProperty("room") || this.validateSelect("room", "roomSelect", "la habitación", { value: room });
      const areaSelect = this.validateSelect("area", "areaSelect", "el area", { value: area });

      return citySelect && officeSelect && workShiftSelect && roomSelect && areaSelect;
    },
  },
  mounted() {
    if (this.isEditUser) {
      this.verifyNext();
    }
  },
};
</script>

<style lang="scss">
.userLocation {
  .customCheckbox{
    font-weight: bold;
    &--RoaAgency{
      color: $pinkRoa
    }
  }
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
  &__contentInput {
    width: 100%;
  }
  &__contentRow {
    @include Flex(row, flex-start);
    gap: $space-12;
    flex-wrap: wrap;
  }
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
  &__containerCol {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    width: 100%;
    gap: 15px;
  }
  &__error {
    display: none;
    margin-top: -10px;
    font-weight: 600;
    font-size: 0.8em;
    color: $primary-color;
  }
}
</style>
