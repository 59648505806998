<script>
  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleCheckedChange() {
        this.$emit("toggleSelection", this.id);
      },
      clearSpaces(txt = "") {
        return txt.replace(/\s+/g, "");
      },
    },
  };
</script>

<template>
  <label :class="['customCheckbox', `customCheckbox--${clearSpaces(label)}`]">
    <div class="customCheckbox__wrapper" :key="isChecked">
      <input class="customCheckbox__input" type="checkbox" :checked="isChecked" @change="handleCheckedChange" />
      <span class="customCheckbox__checkmark" />
    </div>
    <span class="customCheckbox__label">{{ label }}</span>
  </label>
</template>

<style scoped lang="scss">
  .customCheckbox {
    @include Flex(row);
    width: fit-content;
    gap: 6px;
    cursor: pointer;

    &__wrapper {
      position: relative;
      @include Flex(row);
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &__checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid #bfbdbd;
      background-color: #fff;
      transition: background-color 0.3s ease;
      &::after {
        content: "";
        position: absolute;
        display: none;
        top: -3px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 4px;
        height: 7.5px;
        border: solid white;
        border-width: 0 1.7px 1.7px 0;
        transform: rotate(30deg);
      }
    }

    &__input:checked + .customCheckbox__checkmark {
      background-color: $primary-color;
      border-color: $primary-color;

      &::after {
        display: block;
      }
    }

    &__label {
      font-size: 16px;
      user-select: none;
    }
  }
</style>
